/* unplugin-vue-components disabled */import __unplugin_components_0 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, isRef as _isRef, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
import { forceLogout, list as initData } from "@/api/monitor/online";
export default {
  __name: 'index',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const onlineList = ref([]);
    const loading = ref(true);
    const total = ref(0);
    const pageNum = ref(1);
    const pageSize = ref(10);
    const queryParams = ref({
      ipaddr: undefined,
      userName: undefined
    });

    /** 查询登录日志列表 */
    function getList() {
      loading.value = true;
      initData(queryParams.value).then(response => {
        onlineList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      pageNum.value = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      proxy.resetForm("queryRef");
      handleQuery();
    }
    /** 强退按钮操作 */
    function handleForceLogout(row) {
      proxy.$modal.confirm('是否确认强退名称为"' + row.userName + '"的用户?').then(function () {
        return forceLogout(row.tokenId);
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    }
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true,
        "label-width": "68px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "登录地址",
          prop: "ipaddr"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).ipaddr,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).ipaddr = $event),
            placeholder: "请输入登录地址",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "用户名称",
          prop: "userName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).userName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).userName = $event),
            placeholder: "请输入用户名称",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            icon: "Refresh",
            onClick: resetQuery
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _unref(onlineList).slice((_unref(pageNum) - 1) * _unref(pageSize), _unref(pageNum) * _unref(pageSize)),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "序号",
          width: "50",
          type: "index",
          align: "center"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString((_unref(pageNum) - 1) * _unref(pageSize) + scope.$index + 1), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "会话编号",
          align: "center",
          prop: "tokenId",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "登录名称",
          align: "center",
          prop: "userName",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "所属部门",
          align: "center",
          prop: "deptName",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "主机",
          align: "center",
          prop: "ipaddr",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "登录地点",
          align: "center",
          prop: "loginLocation",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "操作系统",
          align: "center",
          prop: "os",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "浏览器",
          align: "center",
          prop: "browser",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "登录时间",
          align: "center",
          prop: "loginTime",
          width: "180"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.loginTime)), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          align: "center",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "Delete",
            onClick: $event => handleForceLogout(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("强退")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['monitor:online:forceLogout']]])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(pageNum),
        "onUpdate:page": _cache[2] || (_cache[2] = $event => _isRef(pageNum) ? pageNum.value = $event : null),
        limit: _unref(pageSize),
        "onUpdate:limit": _cache[3] || (_cache[3] = $event => _isRef(pageSize) ? pageSize.value = $event : null)
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]])]);
    };
  }
};