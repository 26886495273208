/* unplugin-vue-components disabled */import __unplugin_components_1 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/Pagination/index.vue';
import __unplugin_components_0 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/DictTag/index.vue';
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, isRef as _isRef, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5b20e661"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "height": "560px"
  }
};
const _hoisted_2 = {
  class: "dialog-footer"
};
const _hoisted_3 = {
  style: {
    "height": "560px"
  }
};
const _hoisted_4 = {
  class: "dialog-footer"
};
import { getDeviceflat, getDeviceflatAll, updateDeviceflat } from '@/api/base/device';
export default {
  __name: 'deviceflat',
  setup(__props, {
    expose: __expose
  }) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      device_type
    } = proxy.useDict("device_type");
    const visible = ref(false);
    const total = ref(0);
    const pageNum = ref(1);
    const pageSize = ref(10);
    const currentType = ref(undefined);
    const deviceId = ref(undefined);
    const deviceIds = ref([]);
    const firstData = ref([]); //首次加载的全部数据
    const deviceList = ref([]); //选择类型列表缓存的数据
    const currentDeviceList = ref([]); //列表展示的数据
    const devices = ref([]); //已关联列表首次加载的数据
    const currentDevices = ref([]); //已关联列表展示的数据
    const form = ref({});
    const deviceTypeOptions = ref([]);
    const visible1 = ref(false);
    const total1 = ref(0);
    const queryParams = reactive({
      pageNum: 1,
      pageSize: 10,
      id: undefined,
      deviceType: undefined
    });

    // 显示弹框
    function show(currentId, type) {
      deviceId.value = currentId;
      queryParams.id = currentId;
      queryParams.deviceType = type;
      getList();
      visible.value = true;
    }

    /** 关闭按钮 */
    function close() {
      deviceId.value = undefined;
      devices.value = [];
      currentDevices.value = [];
      form.value = {};
      visible.value = false;
    }
    ;

    // 查询表数据
    function getList() {
      getDeviceflat(deviceId.value).then(response => {
        form.value = response.parent;
        devices.value = response.devices;
        currentDevices.value = devices.value.slice(0, pageSize.value);
        total.value = devices.value.length;
      });
    }
    function maintenanceData() {
      getAllList();
      visible1.value = true;
    }

    /**选择行 */
    function clickRow(row) {
      proxy.$refs["tableflatRef"].toggleRowSelection(row, true);
    }

    // 多选框选中数据
    function handleSelectionChange(selection) {
      deviceIds.value = selection.map(item => item.id);
    }
    function close1() {
      firstData.value.forEach(row => {
        proxy.$refs["tableflatRef"].toggleRowSelection(row, false);
      });
      deviceIds.value = [];
      firstData.value = [];
      deviceList.value = [];
      currentDeviceList.value = [];
      total1.value = 0;
      visible1.value = false;
    }
    ;
    function getAllList() {
      getDeviceflatAll(queryParams).then(response => {
        deviceTypeOptions.value = response.data;
        firstData.value = response.deviceList;
        currentDeviceList.value = firstData.value.slice(0, queryParams.pageSize);
        total1.value = firstData.value.length;
        nextTick(() => {
          // 默认选中
          firstData.value.forEach(row => {
            if (row.flag) {
              proxy.$refs["tableflatRef"].toggleRowSelection(row, true);
            }
          });
        });
      });
    }

    //点击页码或者下一页时对相应的数据进行切割
    function currentPageChangeHandler(param) {
      console.log(currentType.value);
      if (currentType.value === '' || currentType.value === undefined) {
        currentDeviceList.value = firstData.value.slice((param.page - 1) * param.limit, param.page * param.limit);
      } else {
        currentDeviceList.value = deviceList.value.slice((param.page - 1) * param.limit, param.page * param.limit);
      }
    }
    function currentPageChange(param) {
      currentDevices.value = devices.value.slice((param.page - 1) * param.limit, param.page * param.limit);
    }

    //切换类型检索新的列表数据
    function selectCurrentType() {
      currentDeviceList.value = [];
      if (currentType.value === '' || currentType.value === undefined) {
        currentDeviceList.value = firstData.value.slice(0, queryParams.pageSize);
        total1.value = firstData.value.length;
      } else {
        deviceList.value = [];
        firstData.value.map((item, index) => {
          if (item.deviceType === currentType.value) {
            deviceList.value.push(item);
          }
        });
        currentDeviceList.value = deviceList.value.slice(0, queryParams.pageSize);
        total1.value = deviceList.value.length;
      }
    }
    function handleSelectFlat() {
      const parentId = deviceId.value;
      const childIds = deviceIds.value.join(',');
      updateDeviceflat({
        parentId: parentId,
        childIds: childIds
      }).then(response => {
        if (childIds == "") {
          proxy.$modal.msgSuccess("设置成功");
        } else {
          proxy.$modal.msgSuccess("关联成功");
        }
        close1();
        getList();
      });
    }
    __expose({
      show
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_dict_tag = __unplugin_components_0;
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_1;
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_dialog, {
        title: "关联设备",
        modelValue: _unref(visible),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(visible) ? visible.value = $event : null),
        width: "800px",
        top: "5vh",
        "append-to-body": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = $event => close())
        }, {
          default: _withCtx(() => [_createTextVNode("返回")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 2,
              offset: 2
            }, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                class: "satpro-button",
                onClick: maintenanceData
              }, {
                default: _withCtx(() => [_createTextVNode("维护")]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8,
              offset: 2
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "设备名称",
                prop: "name"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: _unref(form).name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(form).name = $event),
                  disabled: ""
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8,
              offset: 2
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "设备型号",
                prop: "model"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: _unref(form).model,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(form).model = $event),
                  disabled: ""
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_table, {
          ref: "tableRef",
          data: _unref(currentDevices),
          "header-cell-style": {
            background: 'transparent !important',
            color: '#FFFFFF'
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            key: "id",
            align: "center",
            label: "设备编号",
            prop: "id"
          }), _createVNode(_component_el_table_column, {
            label: "设备类型",
            align: "center",
            prop: "deviceType"
          }, {
            default: _withCtx(scope => [_createVNode(_component_dict_tag, {
              options: _unref(device_type),
              value: scope.row.deviceType
            }, null, 8, ["options", "value"])]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "设备名称",
            align: "center",
            prop: "name"
          }), _createVNode(_component_el_table_column, {
            label: "设备型号",
            align: "center",
            prop: "model"
          }), _createVNode(_component_el_table_column, {
            label: "设备负责人",
            align: "center",
            prop: "ownerUserName"
          })]),
          _: 1
        }, 8, ["data"]), _withDirectives(_createVNode(_component_pagination, {
          total: _unref(total),
          page: _unref(pageNum),
          "onUpdate:page": _cache[2] || (_cache[2] = $event => _isRef(pageNum) ? pageNum.value = $event : null),
          limit: _unref(pageSize),
          "onUpdate:limit": _cache[3] || (_cache[3] = $event => _isRef(pageSize) ? pageSize.value = $event : null),
          onPagination: currentPageChange
        }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]])])]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_dialog, {
        title: "关联设备",
        modelValue: _unref(visible1),
        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _isRef(visible1) ? visible1.value = $event : null),
        width: "800px",
        top: "5vh",
        "append-to-body": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: handleSelectFlat
        }, {
          default: _withCtx(() => [_createTextVNode("提 交")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[10] || (_cache[10] = $event => close1())
        }, {
          default: _withCtx(() => [_createTextVNode("返 回")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 8,
              offset: 2
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "设备类型",
                prop: "deviceType"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: _unref(currentType),
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _isRef(currentType) ? currentType.value = $event : null),
                  clearable: "",
                  placeholder: "请选择",
                  style: {
                    "width": "180px"
                  },
                  onChange: _cache[7] || (_cache[7] = $event => selectCurrentType())
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(deviceTypeOptions), dict => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      key: dict.dictValue,
                      label: dict.dictLabel,
                      value: dict.dictValue
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_table, {
          ref: "tableflatRef",
          data: _unref(currentDeviceList),
          "row-key": row => {
            return row.id;
          },
          onRowClick: clickRow,
          onSelectionChange: handleSelectionChange,
          "header-cell-style": {
            background: 'transparent !important',
            color: '#FFFFFF'
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            type: "selection",
            width: "55",
            "reserve-selection": true
          }), _createVNode(_component_el_table_column, {
            key: "id",
            align: "center",
            label: "设备编号",
            prop: "id"
          }), _createVNode(_component_el_table_column, {
            label: "设备类型",
            align: "center",
            prop: "deviceType"
          }, {
            default: _withCtx(scope => [_createVNode(_component_dict_tag, {
              options: _unref(device_type),
              value: scope.row.deviceType
            }, null, 8, ["options", "value"])]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "设备名称",
            align: "center",
            prop: "name",
            width: "160",
            "show-overflow-tooltip": true
          }), _createVNode(_component_el_table_column, {
            label: "设备型号",
            align: "center",
            prop: "model"
          }), _createVNode(_component_el_table_column, {
            label: "设备负责人",
            align: "center",
            prop: "ownerUserName"
          })]),
          _: 1
        }, 8, ["data", "row-key"]), _withDirectives(_createVNode(_component_pagination, {
          total: _unref(total1),
          page: _unref(queryParams).pageNum,
          "onUpdate:page": _cache[8] || (_cache[8] = $event => _unref(queryParams).pageNum = $event),
          limit: _unref(queryParams).pageSize,
          "onUpdate:limit": _cache[9] || (_cache[9] = $event => _unref(queryParams).pageSize = $event),
          onPagination: currentPageChangeHandler
        }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total1) > 0]])])]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};