/* unplugin-vue-components disabled */import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5da3a6d0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-container"
};
const _hoisted_2 = {
  class: "talkButtonClass"
};
import { useWindowSize } from '@vueuse/core';
import Sidebar from './components/Sidebar/index.vue';
import Rightbar from './components/Rightbar/index.vue';
import TalkPage from '@/components/TalkPage/index.vue';
import { AppMain, Navbar, Settings, Footer } from './components';
import useAppStore from '@/store/modules/app';
import useSettingsStore from '@/store/modules/settings';
import usePermissionStore from "@/store/modules/permission";
const WIDTH = 992; // refer to Bootstrap's responsive design

export default {
  __name: 'index',
  setup(__props) {
    const permission = usePermissionStore();
    const state = ref(true);
    const settingsStore = useSettingsStore();
    const theme = computed(() => settingsStore.theme);
    const sidebar = computed(() => useAppStore().sidebar);
    const rightbar = computed(() => useAppStore().rightbar);
    const device = computed(() => useAppStore().device);
    const classObj = computed(() => ({
      hideSidebar: !sidebar.value.opened,
      openSidebar: sidebar.value.opened,
      withoutAnimation: sidebar.value.withoutAnimation,
      mobile: device.value === 'mobile'
    }));
    const talkData = reactive({
      "talkClass": "maxTalking",
      "maxShow": true
    });
    const onUpdateTalkClass = inClass => {
      console.log("点击了按钮：" + inClass);
      talkData.talkClass = inClass;
      if (inClass === "minTalking") {
        talkData.maxShow = false;
      } else if (inClass === "maxTalking") {
        talkData.maxShow = true;
      } else if (inClass === "notTalking") {
        permission.setTalkShow(false);
        talkData.talkClass = "maxTalking";
        talkData.maxShow = true;
      }
    };
    const {
      width,
      height
    } = useWindowSize();
    watchEffect(() => {
      if (device.value === 'mobile' && sidebar.value.opened) {
        useAppStore().closeSideBar({
          withoutAnimation: false
        });
        useAppStore().closeRightBar({
          withoutAnimation: false
        });
      }
      if (width.value - 1 < WIDTH) {
        useAppStore().toggleDevice('mobile');
        useAppStore().closeSideBar({
          withoutAnimation: true
        });
        useAppStore().closeRightBar({
          withoutAnimation: true
        });
      } else {
        useAppStore().toggleDevice('desktop');
      }
    });
    const settingRef = ref(null);
    function setLayout() {
      settingRef.value.openSetting();
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: _normalizeClass([_unref(classObj), "app-wrapper"]),
        style: _normalizeStyle({
          '--current-color': _unref(theme)
        })
      }, [_unref(sidebar).hide ? (_openBlock(), _createBlock(Sidebar, {
        key: 0,
        class: "sidebar-container"
      })) : _createCommentVNode("", true), _unref(rightbar).hide ? (_openBlock(), _createBlock(Rightbar, {
        key: 1,
        class: "rightbar-container"
      })) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, [_createVNode(_unref(Navbar), {
        onSetLayout: setLayout
      }), _createVNode(_unref(AppMain)), _createVNode(_unref(Settings), {
        ref_key: "settingRef",
        ref: settingRef
      }, null, 512)]), _createVNode(_unref(Footer))], 6), _unref(permission).talkShow ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_unref(talkData).talkClass)
      }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("button", {
        class: "talkButton",
        onClick: _cache[0] || (_cache[0] = $event => onUpdateTalkClass('notTalking'))
      }, "×"), _createElementVNode("div", null, [_withDirectives(_createElementVNode("button", {
        class: "talkButton",
        onClick: _cache[1] || (_cache[1] = $event => onUpdateTalkClass('minTalking'))
      }, "-", 512), [[_vShow, _unref(talkData).maxShow]])]), _createElementVNode("div", null, [_withDirectives(_createElementVNode("button", {
        class: "talkButton",
        onClick: _cache[2] || (_cache[2] = $event => onUpdateTalkClass('maxTalking'))
      }, "□", 512), [[_vShow, !_unref(talkData).maxShow]])])]), _createVNode(TalkPage)], 2)) : _createCommentVNode("", true)], 64);
    };
  }
};