/* unplugin-vue-components disabled */import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { updateUserProfile } from "@/api/system/user";
export default {
  __name: 'userInfo',
  props: {
    user: {
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const {
      proxy
    } = getCurrentInstance();
    const rules = ref({
      nickName: [{
        required: true,
        message: "用户昵称不能为空",
        trigger: "blur"
      }],
      email: [{
        required: true,
        message: "邮箱地址不能为空",
        trigger: "blur"
      }, {
        type: "email",
        message: "请输入正确的邮箱地址",
        trigger: ["blur", "change"]
      }],
      phonenumber: [{
        required: true,
        message: "手机号码不能为空",
        trigger: "blur"
      }, {
        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
        message: "请输入正确的手机号码",
        trigger: "blur"
      }]
    });

    /** 提交按钮 */
    function submit() {
      proxy.$refs.userRef.validate(valid => {
        if (valid) {
          updateUserProfile(props.user).then(response => {
            proxy.$modal.msgSuccess("修改成功");
          });
        }
      });
    }
    ;
    /** 关闭按钮 */
    function close() {
      proxy.$router.back();
    }
    ;
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createBlock(_component_el_form, {
        ref: "userRef",
        model: __props.user,
        rules: _unref(rules),
        "label-width": "80px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "用户昵称",
          prop: "nickName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: __props.user.nickName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => __props.user.nickName = $event),
            maxlength: "30"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "手机号码",
          prop: "phonenumber"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: __props.user.phonenumber,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => __props.user.phonenumber = $event),
            maxlength: "11"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "邮箱",
          prop: "email"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: __props.user.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => __props.user.email = $event),
            maxlength: "50"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "性别"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_group, {
            modelValue: __props.user.sex,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => __props.user.sex = $event)
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio, {
              label: "0"
            }, {
              default: _withCtx(() => [_createTextVNode("男")]),
              _: 1
            }), _createVNode(_component_el_radio, {
              label: "1"
            }, {
              default: _withCtx(() => [_createTextVNode("女")]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            class: "satpro-button",
            onClick: submit
          }, {
            default: _withCtx(() => [_createTextVNode("保存")]),
            _: 1
          }), _createVNode(_component_el_button, {
            class: "satpro-button",
            onClick: close
          }, {
            default: _withCtx(() => [_createTextVNode("关闭")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules"]);
    };
  }
};