/* unplugin-vue-components disabled */import __unplugin_components_2 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/Pagination/index.vue';
import __unplugin_components_1 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/DictTag/index.vue';
import __unplugin_components_0 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/RightToolbar/index.vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, isRef as _isRef, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "page"
};
const _hoisted_2 = {
  class: "ele-wrapper-bgCard"
};
const _hoisted_3 = {
  class: "app-container"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  class: "dialog-footer"
};
import { optionselect as getDictOptionselect, getType } from "@/api/system/dict/type";
import { listData, getData, delData, addData, updateData } from "@/api/system/dict/data";
export default {
  __name: 'data',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      sys_normal_disable
    } = proxy.useDict("sys_normal_disable");
    const dataList = ref([]);
    const open = ref(false);
    const loading = ref(true);
    const showSearch = ref(true);
    const ids = ref([]);
    const single = ref(true);
    const multiple = ref(true);
    const total = ref(0);
    const title = ref("");
    const defaultDictType = ref("");
    const typeOptions = ref([]);
    const route = useRoute();
    // 数据标签回显样式
    const listClassOptions = ref([{
      value: "default",
      label: "默认"
    }, {
      value: "primary",
      label: "主要"
    }, {
      value: "success",
      label: "成功"
    }, {
      value: "info",
      label: "信息"
    }, {
      value: "warning",
      label: "警告"
    }, {
      value: "danger",
      label: "危险"
    }]);
    const data = reactive({
      form: {},
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        dictName: undefined,
        dictType: undefined,
        status: undefined
      },
      rules: {
        dictLabel: [{
          required: true,
          message: "数据标签不能为空",
          trigger: "blur"
        }],
        dictValue: [{
          required: true,
          message: "数据键值不能为空",
          trigger: "blur"
        }],
        dictSort: [{
          required: true,
          message: "数据顺序不能为空",
          trigger: "blur"
        }]
      }
    });
    const {
      queryParams,
      form,
      rules
    } = toRefs(data);

    /** 查询字典类型详细 */
    function getTypes(dictId) {
      getType(dictId).then(response => {
        queryParams.value.dictType = response.data.dictType;
        defaultDictType.value = response.data.dictType;
        getList();
      });
    }

    /** 查询字典类型列表 */
    function getTypeList() {
      getDictOptionselect().then(response => {
        typeOptions.value = response.data;
      });
    }
    /** 查询字典数据列表 */
    function getList() {
      loading.value = true;
      listData(queryParams.value).then(response => {
        dataList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    /** 取消按钮 */
    function cancel() {
      open.value = false;
      reset();
    }
    /** 表单重置 */
    function reset() {
      form.value = {
        dictCode: undefined,
        dictLabel: undefined,
        dictValue: undefined,
        cssClass: undefined,
        listClass: "default",
        dictSort: 0,
        status: "0",
        remark: undefined
      };
      proxy.resetForm("dataRef");
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.value.pageNum = 1;
      getList();
    }
    /** 返回按钮操作 */
    function handleClose() {
      const obj = {
        path: "/system/departmentCard/dict"
      };
      proxy.$router.push(obj);
    }
    /** 重置按钮操作 */
    function resetQuery() {
      proxy.resetForm("queryRef");
      queryParams.value.dictType = defaultDictType;
      handleQuery();
    }
    /** 新增按钮操作 */
    function handleAdd() {
      reset();
      open.value = true;
      title.value = "添加字典数据";
      form.value.dictType = queryParams.value.dictType;
    }
    /** 多选框选中数据 */
    function handleSelectionChange(selection) {
      ids.value = selection.map(item => item.dictCode);
      single.value = selection.length != 1;
      multiple.value = !selection.length;
    }
    /** 修改按钮操作 */
    function handleUpdate(row) {
      reset();
      const dictCode = row.dictCode || ids.value;
      getData(dictCode).then(response => {
        form.value = response.data;
        open.value = true;
        title.value = "修改字典数据";
      });
    }
    /** 提交按钮 */
    function submitForm() {
      proxy.$refs["dataRef"].validate(valid => {
        if (valid) {
          if (form.value.dictCode != undefined) {
            updateData(form.value).then(response => {
              proxy.$modal.msgSuccess("修改成功");
              open.value = false;
              getList();
            });
          } else {
            addData(form.value).then(response => {
              proxy.$modal.msgSuccess("新增成功");
              open.value = false;
              getList();
            });
          }
        }
      });
    }
    /** 删除按钮操作 */
    function handleDelete(row) {
      const dictCodes = row.dictCode || ids.value;
      proxy.$modal.confirm('是否确认删除字典编码为"' + dictCodes + '"的数据项？').then(function () {
        return delData(dictCodes);
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    }
    /** 导出按钮操作 */
    function handleExport() {
      proxy.download("system/dict/data/export", {
        ...queryParams.value
      }, `dict_data_${new Date().getTime()}.xlsx`);
    }
    getTypes(route.params && route.params.dictId);
    getTypeList();
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_right_toolbar = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_dict_tag = __unplugin_components_1;
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_2;
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_withDirectives(_createVNode(_component_el_form, {
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true,
        "label-width": "68px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "字典名称",
          prop: "dictType"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParams).dictType,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).dictType = $event)
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(typeOptions), item => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: item.dictId,
                label: item.dictName,
                value: item.dictType
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "字典标签",
          prop: "dictLabel"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).dictLabel,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).dictLabel = $event),
            placeholder: "请输入字典标签",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "状态",
          prop: "status"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParams).status,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(queryParams).status = $event),
            placeholder: "数据状态",
            clearable: ""
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_normal_disable), dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.value,
                label: dict.label,
                value: dict.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            class: "satpro-button",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            class: "satpro-button",
            icon: "Refresh",
            onClick: resetQuery
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), [[_vShow, _unref(showSearch)]]), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            class: "satpro-button",
            plain: "",
            icon: "Plus",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增")]),
            _: 1
          })), [[_directive_hasPermi, ['system:dict:add']]])]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            class: "satpro-button",
            plain: "",
            icon: "Edit",
            disabled: _unref(single),
            onClick: handleUpdate
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['system:dict:edit']]])]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            class: "satpro-button",
            plain: "",
            icon: "Delete",
            disabled: _unref(multiple),
            onClick: handleDelete
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['system:dict:remove']]])]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            class: "satpro-button",
            plain: "",
            icon: "Download",
            onClick: handleExport
          }, {
            default: _withCtx(() => [_createTextVNode("导出")]),
            _: 1
          })), [[_directive_hasPermi, ['system:dict:export']]])]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            class: "satpro-button",
            plain: "",
            icon: "Close",
            onClick: handleClose
          }, {
            default: _withCtx(() => [_createTextVNode("关闭")]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_right_toolbar, {
          showSearch: _unref(showSearch),
          "onUpdate:showSearch": _cache[3] || (_cache[3] = $event => _isRef(showSearch) ? showSearch.value = $event : null),
          onQueryTable: getList
        }, null, 8, ["showSearch"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _unref(dataList),
        onSelectionChange: handleSelectionChange,
        "cell-style": {
          'padding-top': '12px'
        },
        "header-cell-style": {
          background: 'transparent !important',
          color: '#FFFFFF'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "55",
          align: "center"
        }), _createVNode(_component_el_table_column, {
          label: "字典编码",
          align: "center",
          prop: "dictCode"
        }), _createVNode(_component_el_table_column, {
          label: "字典标签",
          align: "center",
          prop: "dictLabel"
        }, {
          default: _withCtx(scope => [scope.row.listClass == '' || scope.row.listClass == 'default' ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(scope.row.dictLabel), 1)) : (_openBlock(), _createBlock(_component_el_tag, {
            key: 1,
            type: scope.row.listClass == 'primary' ? '' : scope.row.listClass
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.dictLabel), 1)]),
            _: 2
          }, 1032, ["type"]))]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "字典键值",
          align: "center",
          prop: "dictValue"
        }), _createVNode(_component_el_table_column, {
          label: "字典排序",
          align: "center",
          prop: "dictSort"
        }), _createVNode(_component_el_table_column, {
          label: "状态",
          align: "center",
          prop: "status"
        }, {
          default: _withCtx(scope => [_createVNode(_component_dict_tag, {
            options: _unref(sys_normal_disable),
            value: scope.row.status
          }, null, 8, ["options", "value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "备注",
          align: "center",
          prop: "remark",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          align: "center",
          prop: "createTime",
          width: "180"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.createTime)), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          align: "center",
          width: "180",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "Edit",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:dict:edit']]]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "Delete",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:dict:remove']]])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[4] || (_cache[4] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[5] || (_cache[5] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        title: _unref(title),
        modelValue: _unref(open),
        "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => _isRef(open) ? open.value = $event : null),
        width: "500px",
        "append-to-body": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref: "dataRef",
          model: _unref(form),
          rules: _unref(rules),
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "字典类型"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).dictType,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(form).dictType = $event),
              disabled: true
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "数据标签",
            prop: "dictLabel"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).dictLabel,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).dictLabel = $event),
              placeholder: "请输入数据标签"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "数据键值",
            prop: "dictValue"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).dictValue,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).dictValue = $event),
              placeholder: "请输入数据键值"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "样式属性",
            prop: "cssClass"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).cssClass,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(form).cssClass = $event),
              placeholder: "请输入样式属性"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "显示排序",
            prop: "dictSort"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              modelValue: _unref(form).dictSort,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _unref(form).dictSort = $event),
              "controls-position": "right",
              min: 0
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "回显样式",
            prop: "listClass"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).listClass,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _unref(form).listClass = $event)
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(listClassOptions), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.value,
                  label: item.label + '(' + item.value + ')',
                  value: item.value
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "状态",
            prop: "status"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio_group, {
              modelValue: _unref(form).status,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _unref(form).status = $event)
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_normal_disable), dict => {
                return _openBlock(), _createBlock(_component_el_radio, {
                  key: dict.value,
                  label: dict.value
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(dict.label), 1)]),
                  _: 2
                }, 1032, ["label"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "备注",
            prop: "remark"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).remark,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => _unref(form).remark = $event),
              type: "textarea",
              placeholder: "请输入内容"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["title", "modelValue"])])])]);
    };
  }
};