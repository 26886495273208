/* unplugin-vue-components disabled */import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c10112a0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-main"
};
export default {
  __name: 'AppMain',
  setup(__props) {
    const route = useRoute();
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("section", _hoisted_1, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component,
          route
        }) => [_createVNode(_Transition, {
          name: "fade-transform",
          mode: "out-in"
        }, {
          default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: route.path
          }))]),
          _: 2
        }, 1024)]),
        _: 1
      })]);
    };
  }
};