/* unplugin-vue-components disabled */import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2d08d445"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = ["id"];
export default {
  __name: 'index',
  props: {
    url: {
      type: String,
      // default: "ws://113.200.202.153:10018/live/zhrwc3.live.flv"
      default: "https://satprowide.com:5078/live/ACE.live.flv"
    },
    close: {
      type: Boolean,
      default: false
    },
    /**
     * 宽高比
     */
    aspectRatio: {
      type: String,
      default: "16/9"
    }
  },
  setup(__props, {
    expose: __expose
  }) {
    const props = __props;
    watch(props.url, newValue => {
      reInitAndPlay();
    });
    watch(props.aspectRatio, newValue => {
      setJessibucaStyle(true);
    });
    const jessibucaPlayer = ref(null);
    const containerId = ref("webplayer_" + parseInt(Math.random() * 100000000) + Date.now());
    const showOperateBtns = ref(true);
    const forceNoOffscreen = ref(true);
    const jessibucaStyle = ref({});
    __expose({
      videoScreenShot,
      setJessibucaStyle,
      videoStartRecord,
      videoStopRecordAndSave,
      videodestroy,
      videoFullScreen
    });
    onMounted(() => {
      setJessibucaStyle(false);
      reInitAndPlay();
    });

    // 初始化播放器
    function initPlayer() {
      jessibucaPlayer.value = new JessibucaProMulti({
        container: document.getElementById(containerId.value),
        videoBuffer: 0.2,
        // 设置最小缓冲时长，单位秒，播放器会自动消除延迟。
        videoBufferDelay: 0.3,
        //设置最大缓冲延迟时长，单位秒，最小不能低于1秒，如果延迟超过该值，就会进行丢帧处理。
        useSIMD: true,
        //是否使用SIMD 软解码，仅仅wasm软解码的时候有效
        isResize: false,
        //1. 当为`true`的时候：视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边。 等同于 `setScaleMode(1)`2. 当为`false`的时候：视频画面完全填充canvas区域,画面会被拉伸。等同于 `setScaleMode(0)`
        text: "",
        loadingText: "加载中",
        //加载过程中文案
        useMSE: true,
        //是否开启MediaSource硬解码
        isMulti: true,
        //当多路播放的时候，可以配置起来，这样在看日志的时候，会有一个uuid来区分
        debug: false,
        //是否开启控制台调试打印
        showBandwidth: true,
        // 显示网速
        heartTimeoutReplayTimes: -1,
        loadingTimeoutReplayTimes: -1,
        operateBtns: {
          fullscreen: showOperateBtns.value,
          //是否显示全屏按钮
          screenshot: showOperateBtns.value,
          //是否显示截图按钮
          play: showOperateBtns.value,
          //是否显示播放暂停按钮
          audio: false,
          //是否显示声音按钮
          recorder: false,
          //是否显示录制按钮
          close: props.close //是否显示关闭按钮
        },

        forceNoOffscreen: forceNoOffscreen.value,
        //是否不使用离屏模式（提升渲染能力）
        isNotMute: false,
        //是否开启声音，默认是关闭声音播放的。
        decoder: '/jessibuca-pro-permanent/decoder-pro-simd.js'
      });
    }
    function setJessibucaStyle(e) {
      let style = {};
      if (!e) {
        style.width = '100%';
        style.height = '100%';
      } else {
        style.width = 'auto';
        style.height = '100%';
        style.aspectRatio = props.aspectRatio || "4/3";
      }
      jessibucaStyle.value = style;
    }
    function initPlayerAndPlay() {
      jessibucaPlayer.value.pause();
      jessibucaPlayer.value.destroy().then(() => {
        jessibucaPlayer.value = null;
        initPlayer();
        if (jessibucaPlayer.value) {
          jessibucaPlayer.value.play(props.url);
          jessibucaPlayer.value.on("playFailedAndPaused", () => {
            initPlayerAndPlay();
          });
        }
      });
    }

    // 播放
    function play() {
      jessibucaPlayer.value.play(props.url);
      playerLoad();
    }

    //播放器重新初始化播放
    function reInitAndPlay() {
      console.log("播放器初始化");
      if (jessibucaPlayer.value) {
        console.log("暂停流播发并销毁播放器");
        pauseVideoStreamAndDestoryPlayer();
      } else {
        initPlayer();
        jessibucaPlayer.value.on("playFailedAndPaused", () => {
          initPlayerAndPlay();
        });
        play();
      }
    }
    function playerLoad() {
      if (jessibucaPlayer.value) {
        jessibucaPlayer.value.on("load", function () {
          console.log('load');
        });
      } else {
        initPlayer();
        play();
      }
    }

    /**
     * 暂停流播发并销毁播放器
     */
    function pauseVideoStreamAndDestoryPlayer() {
      if (jessibucaPlayer.value) {
        jessibucaPlayer.value.pause();
        jessibucaPlayer.value.destroy();
      } else {
        initPlayer();
      }
    }
    function videodestroy() {
      jessibucaPlayer.value.destroy();
    }
    // 视频截图功能
    function videoScreenShot() {
      jessibucaPlayer.value.screenshot(new Date().getTime());
    }

    // 录屏
    function videoStartRecord() {
      jessibucaPlayer.value.startRecord(new Date().getTime(), "mp4");
    }
    function videoStopRecordAndSave() {
      jessibucaPlayer.value.stopRecordAndSave("download", new Date().getTime());
    }

    // 全屏
    function videoFullScreen() {
      jessibucaPlayer.value.setFullscreen(true);
    }
    onBeforeUnmount(() => {
      pauseVideoStreamAndDestoryPlayer();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        id: _unref(containerId),
        style: _normalizeStyle(_unref(jessibucaStyle))
      }, null, 12, _hoisted_2)]);
    };
  }
};