/* unplugin-vue-components disabled */import __unplugin_components_0 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { listDbTable, importTable } from "@/api/tool/gen";
export default {
  __name: 'importTable',
  emits: ["ok"],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    const total = ref(0);
    const visible = ref(false);
    const tables = ref([]);
    const dbTableList = ref([]);
    const {
      proxy
    } = getCurrentInstance();
    const queryParams = reactive({
      pageNum: 1,
      pageSize: 10,
      tableName: undefined,
      tableComment: undefined
    });

    /** 查询参数列表 */
    function show() {
      getList();
      visible.value = true;
    }
    /** 单击选择行 */
    function clickRow(row) {
      proxy.$refs.table.toggleRowSelection(row);
    }
    /** 多选框选中数据 */
    function handleSelectionChange(selection) {
      tables.value = selection.map(item => item.tableName);
    }
    /** 查询表数据 */
    function getList() {
      listDbTable(queryParams).then(res => {
        dbTableList.value = res.rows;
        total.value = res.total;
      });
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.pageNum = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      proxy.resetForm("queryRef");
      handleQuery();
    }
    /** 导入按钮操作 */
    function handleImportTable() {
      const tableNames = tables.value.join(",");
      if (tableNames == "") {
        proxy.$modal.msgError("请选择要导入的表");
        return;
      }
      importTable({
        tables: tableNames
      }).then(res => {
        proxy.$modal.msgSuccess(res.msg);
        if (res.code === 200) {
          visible.value = false;
          emit("ok");
        }
      });
    }
    __expose({
      show
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        title: "导入表",
        modelValue: _unref(visible),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(visible) ? visible.value = $event : null),
        width: "800px",
        top: "5vh",
        "append-to-body": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: handleImportTable
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = $event => visible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          ref: "queryRef",
          inline: true
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "表名称",
            prop: "tableName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).tableName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).tableName = $event),
              placeholder: "请输入表名称",
              clearable: "",
              onKeyup: _withKeys(handleQuery, ["enter"])
            }, null, 8, ["modelValue", "onKeyup"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "表描述",
            prop: "tableComment"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).tableComment,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).tableComment = $event),
              placeholder: "请输入表描述",
              clearable: "",
              onKeyup: _withKeys(handleQuery, ["enter"])
            }, null, 8, ["modelValue", "onKeyup"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              icon: "Search",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            }), _createVNode(_component_el_button, {
              icon: "Refresh",
              onClick: resetQuery
            }, {
              default: _withCtx(() => [_createTextVNode("重置")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"]), _createVNode(_component_el_row, null, {
          default: _withCtx(() => [_createVNode(_component_el_table, {
            onRowClick: clickRow,
            ref: "table",
            data: _unref(dbTableList),
            onSelectionChange: handleSelectionChange,
            height: "260px"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_table_column, {
              type: "selection",
              width: "55"
            }), _createVNode(_component_el_table_column, {
              prop: "tableName",
              label: "表名称",
              "show-overflow-tooltip": true
            }), _createVNode(_component_el_table_column, {
              prop: "tableComment",
              label: "表描述",
              "show-overflow-tooltip": true
            }), _createVNode(_component_el_table_column, {
              prop: "createTime",
              label: "创建时间"
            }), _createVNode(_component_el_table_column, {
              prop: "updateTime",
              label: "更新时间"
            })]),
            _: 1
          }, 8, ["data"]), _withDirectives(_createVNode(_component_pagination, {
            total: _unref(total),
            page: _unref(queryParams).pageNum,
            "onUpdate:page": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageNum = $event),
            limit: _unref(queryParams).pageSize,
            "onUpdate:limit": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageSize = $event),
            onPagination: getList
          }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};